import useGlobalState from 'hooks/useGlobalState';
import { PropsWithChildren } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import Button from 'components/Button';

import { IProductWithPrices, TBillingPeriod, TPrices } from 'types/interfaces';

import styles from './PricingPlan.module.scss';

interface IProps {
  product: IProductWithPrices;
  products: IProductWithPrices[];
  billingPeriod: TBillingPeriod;
  index: number;
  onClick: (priceId: string) => () => void;
}

const PricingPlan: React.FC<PropsWithChildren<IProps>> = props => {
  const { product, products, billingPeriod, index, onClick } = props;

  const [globalState, _setGlobalState] = useGlobalState();

  const { t, i18n } = useTranslation();
  const { subscriptionPlan, subscriptionPrice, hasActiveOrRenewableSubscription } = globalState;

  const formatPrice = (priceInCents: number) => {
    const priceInWholeUnits = Number((priceInCents / 100).toFixed(2));

    return new Intl.NumberFormat(navigator.language).format(priceInWholeUnits);
  };

  const getCurrencySign = (currency: string) => {
    const symbol = new Intl.NumberFormat('en', { style: 'currency', currency })
      .formatToParts(0)
      .find(x => x.type === 'currency');

    return symbol && symbol.value;
  };

  const getPriceEntryByBillingPeriod = (prices: TPrices, billingPeriod: TBillingPeriod) => {
    const priceEntries = Object.entries(prices);
    const priceEntryWithSelectedBillingPeriod = priceEntries?.find(([_priceId, price]) => {
      return price.interval === billingPeriod;
    });

    return priceEntryWithSelectedBillingPeriod;
  };

  const getPlanName = () => {
    return product.metadata.name && i18n.exists(product.metadata.name)
      ? t(product.metadata.name)
      : product.name;
  };

  const getPlanDescription = () => {
    return product.metadata.description && i18n.exists(product.metadata.description)
      ? t(product.metadata.description)
      : product.description;
  };

  const priceEntries = Object.entries(product.prices);
  const priceEntryWithSelectedBillingPeriod = getPriceEntryByBillingPeriod(
    product.prices,
    billingPeriod
  );
  const monthlyPriceEntry = getPriceEntryByBillingPeriod(product.prices, 'month');
  const yearlyPriceEntry = getPriceEntryByBillingPeriod(product.prices, 'year');
  const anyActivePriceEntry = priceEntries.find(([_priceId, price]) => price.active);
  const visiblePriceEntry = priceEntryWithSelectedBillingPeriod || anyActivePriceEntry || [];

  const [visiblePriceId, visiblePrice] = visiblePriceEntry;
  const [_monthlyPriceId, monthlyPrice] = monthlyPriceEntry || [];
  const [_yearlyPriceId, yearlyPrice] = yearlyPriceEntry || [];

  const isCurrentPlan =
    subscriptionPlan?.productId === product.productId &&
    subscriptionPrice?.priceId === visiblePriceId;

  const isBestPlan = index + 1 === products.length;

  const monthlyPriceForAYear = monthlyPrice ? monthlyPrice.unit_amount * 12 : 0;
  const currencySign = visiblePrice ? getCurrencySign(visiblePrice.currency) : '';

  const shouldShowMonthlyPriceForAYear =
    billingPeriod === 'year' &&
    monthlyPrice &&
    yearlyPrice &&
    monthlyPriceForAYear > yearlyPrice?.unit_amount;

  return (
    <div
      className={cn(styles.planContainer, {
        [styles.paidPlanContainer]: visiblePriceId,
        [styles.selectedPlanContainer]: isCurrentPlan,
      })}
    >
      <div
        className={cn(styles.plan, styles.planAnimated, {
          [styles.planPaid]: visiblePriceId,
          [styles.planSelected]: isCurrentPlan,
          [styles[`plan${index}`]]: index,
        })}
        onClick={visiblePriceId && !isCurrentPlan ? onClick(visiblePriceId) : undefined}
      >
        {isCurrentPlan && <div className={cn(styles.planBadge)}>{t('currentPlan')}</div>}

        {!isCurrentPlan && isBestPlan && (
          <div className={cn(styles.planBadge, styles.planBadgeAnimated)}>{t('mostPopular')}</div>
        )}

        <div className={styles.planName}>{getPlanName()}</div>
        <div className={styles.planDescription}>
          {getPlanDescription()}
          <br />
          {visiblePrice && (
            <>
              (~
              {visiblePrice &&
                formatPrice(visiblePrice.unit_amount / Number(product.metadata.planStorageInGB))}
              {currencySign} {t('per')} GB {t('per')} {t(billingPeriod).toLowerCase()})
            </>
          )}
        </div>

        <div className={styles.planPrice}>
          {visiblePrice ? (
            <>
              {shouldShowMonthlyPriceForAYear && (
                <div className={cn(styles.planPriceValue, styles.priceValueStrikethrough)}>
                  <div className={styles.planPriceCurrency}>{currencySign}</div>
                  <div className={styles.planPriceNumericValue}>
                    {formatPrice(monthlyPrice.unit_amount * 12)}
                  </div>
                </div>
              )}

              <div className={styles.planPriceValue}>
                <div className={styles.planPriceCurrency}>{currencySign}</div>
                <div className={styles.planPriceNumericValue}>
                  {formatPrice(visiblePrice.unit_amount)}
                </div>
              </div>
              <div className={styles.planPricePeriod}>
                {t('per')} <br /> {t(visiblePrice.interval).toLowerCase()}
              </div>
            </>
          ) : (
            <div className={styles.planPriceValue}>0€</div>
          )}
        </div>

        <div className={styles.planBtnContainer}>
          <Button type="flat" className={styles.planBtn} disabled={isCurrentPlan}>
            {hasActiveOrRenewableSubscription
              ? t(isCurrentPlan ? 'selected' : 'select')
              : t('subscribe')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PricingPlan;
