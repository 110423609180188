import { useRef } from 'react';
import { cssTransition, ToastContainer } from 'react-toastify';
import { Outlet, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useUpdateEffect } from 'react-use';
import cn from 'classnames';
import smoothScrollIntoView from 'smooth-scroll-into-view-if-needed';

import useAuth from 'hooks/useAuth';
import useGlobalState from 'hooks/useGlobalState';

import { INDEX_ROUTE } from 'constants/routes';
import AuthModal from 'components/AuthModal';
import Button from 'components/Button';
import CreateNewHashtag from 'components/CreateNewHashtag';
import NavBar from 'components/NavBar';

import styles from './MainLayout.module.scss';

const transition = cssTransition({
  enter: styles.noopEnterAnimation,
  exit: styles.exitAnimation,
  collapse: false,
});

const MainLayout = () => {
  const { t } = useTranslation();
  const [globalState] = useGlobalState();
  const { isAuthLoading, isAuthorized } = globalState;
  const { showAuthModal } = useAuth();

  const location = useLocation();
  const layoutRef = useRef<HTMLDivElement>(null);

  useUpdateEffect(() => {
    if (!layoutRef.current) return;

    smoothScrollIntoView(layoutRef.current, {
      duration: 0,
      block: 'start',
    });
  }, [location]);

  return (
    <>
      {/* <StrictMode> */}
      <>
        <div className={styles.mainLayout} ref={layoutRef}>
          <NavBar />

          <Outlet />
        </div>
      </>

      {!isAuthLoading && (
        <div
          className={cn(styles.btnContainer, {
            [styles.indexPageBtnContainer]: location.pathname === INDEX_ROUTE,
          })}
        >
          {isAuthorized ? (
            <CreateNewHashtag />
          ) : (
            <Button type="dark" className={styles.signInBtn} onClick={showAuthModal}>
              {t('signIn')}
            </Button>
          )}
        </div>
      )}

      <AuthModal />
      <ToastContainer className={styles.toastContainer} transition={transition}></ToastContainer>

      {/* </StrictMode> */}
    </>
  );
};

export default MainLayout;
