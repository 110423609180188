const ja = {
  hey: 'こんにちは、',
  you: 'あなた！',
  nofiles: 'このフォルダにファイルが見つかりませんでした。',
  folder: ' フォルダ。',
  hashtag: ' ハッシュタグ。',
  uploadfiles: 'ファイルをアップロード',
  or: 'または',
  back: '戻る',
  searchAnotherHashtag: '別のハッシュタグを検索する',
  passwordEnterTitle: 'このハッシュタグは保護されています。',
  passwordEnterPromt: '続行するにはパスワードを入力してください。',
  password: 'パスワード：',
  cancel: 'キャンセル',
  submit: 'OK',
  search: '検索',
  hashtagDoesntExist: 'このハッシュタグは存在しません。',
  folderDoesntExist: 'このフォルダは存在しません。',
  createHashtag: 'ハッシュタグを作成する',
  checkHashtag: '#ハッシュタグ',
  instructions: '手順：',
  downloadApp: '1. アプリをダウンロードして #ハッシュタグ を作成します。',
  createAndUpload: '2. この #ハッシュタグ にファイルをアップロードします。',
  typeIn: '3. ここでファイルをすべて確認できます。',
  nameIsAlreadyTaken: 'この名前はすでに使用されています。別の名前を選択してください。',
  fileType: 'ファイル',
  folderType: 'フォルダ',
  fileWasSuccessfullyRenamed: '{{type}} が正常に名前変更されました',
  fileWasSuccessfullyDeleted: 'ファイルが正常に削除されました',
  failedConnectToCloud:
    'クラウドに接続できませんでした。ページを再読み込みして再試行するか、サポートチームにお問い合わせください。',
  authModalTitle: '続行するオプションを選択してください',
  signIn: 'ログイン',
  signInWithApple: 'Appleでログイン',
  signInWithGoogle: 'Googleでログイン',
  createFolderInputPlaceholder: 'フォルダ名',
  createFolderModalTitle: 'フォルダ名を入力してください',
  hashtagAlreadyExists:
    '指定されたハッシュタグは既に作成されています。別のハッシュタグ名を選択してください。',
  createHashtagModalTitle: '新しいハッシュタグを考えてください',
  createHashtagRequirement1: '最小6文字',
  createHashtagRequirement2: '最小1数字',
  createHashtagRequirement3: '空白または特殊文字は使用しないでください',
  createHashtagInputPlaceholder: '#ハッシュタグ',
  dropzoneText: 'ファイルをここにドラッグアンドドロップ',
  dropzoneSmallText: '最大 {{MAX_FILES_PER_UPLOAD}} ファイル',
  dropzoneBtnText: ' ファイルを選択',
  fileMoreActionsTitle: 'その他のアクション',
  fileActionDelete: '削除',
  fileActionEdit: '編集',
  fileRenameModalTitle: '名前変更',
  navbarWebAppItemTitle: 'Webアプリ',
  navbariOSAppItemTitle: 'iOSアプリ',
  navbarInfoItemTitle: '情報',
  navbarPricingItemTitle: '価格',
  navbarHelpItemTitle: 'ヘルプ',
  navbarImprintItemTitle: 'インプリント',
  notFoundPageTitle: '要求されたページが見つかりませんでした！',
  notFoundPageSubtitle: '代わりにホームページをご覧ください',
  notFoundPageBtnText: 'ホームページに移動',
  pageDropzoneText: 'ファイルをここにドラッグアンドドロップ',
  fileUploaded: 'ファイルが正常にアップロードされました！',
  filesUploaded: 'ファイルが正常にアップロードされました！',
  fileUploadModalSelectedFiles: '選択したファイル',
  fileUploadModalClearFiles: 'ファイルをクリア',
  defaultErrorMessage:
    '問題が発生しました。後でもう一度試してください。または、サポートチームにお問い合わせください。',
  addFolder: 'フォルダを追加',
  addFiles: 'ファイルを追加',
  profileUsername: 'ユーザー名',
  profileUsedStorage: '使用済みストレージ',
  profileOutOf: 'のうち',
  profileSignOut: 'ログアウト',
  profileSupport: 'サポート',
  outOfStorageTitle: 'ストレージ不足！',
  outOfStorageText:
    'このファイルをアップロードするための十分なストレージスペースがありません。別のストレージプランにアップグレードして拡張できます。',
  checkPricingPlans: '料金プランを確認する',
  noFilesFound: 'ファイルが見つかりませんでした。',
  setHashtagPasswordModalTitle: 'ハッシュタグのパスワードを入力してください',
  protectWithPassword: 'ハッシュタグにパスワードを設定する',
  removePasswordProtection: 'パスワード保護を解除する',
  hashtagPasswordUpdated: 'パスワードが正常に設定されました',
  hashtagPasswordReset: 'パスワードが正常に削除されました',
  settings: '設定',
  buyStorage: 'ストレージを購入',
  deleteAccount: 'アカウントを削除',
  usernameChangeModalTitle: 'ユーザー名はアップロードするファイルにリンクされます',
  profileAccount: 'アカウント',
  manageSubscription: 'サブスクリプションを管理する',
  subscriptionSuccessMessage:
    'サブスクリプションに成功しました！ 選択したプランに応じてストレージが更新されました。',
  pricingPageTitle: 'プランを選択...',
  pricingPageSubtitle: '柔軟な価格設定、毎月キャンセル可能',
  pricingFaqTitle: 'よくある質問',
  pricingFaqQuestion1: '追加のストレージ容量を購入する方法は？',
  pricingFaqAnswer1:
    'ファイルをhashcloudにアップロードするには、利用可能な方法でサインアップしてください。その後、1 GBまで無料でアップロードできます。より多くのストレージ容量が必要な場合は、月額€0.99で10 GB、€4.99で100 GB、€9.99で1000 GBの中から選択できます。',
  pricingFaqQuestion2: '支払い方法はどのように機能しますか？',
  pricingFaqAnswer2:
    '支払いはお好みの支払い方法を使用してStripeを介して安全に処理されます。お客様の情報は安全かつプライベートに保持されます。アプリケーションはこれらの情報にアクセスしません。',
  pricingFaqQuestion3: '使用されているストレージ容量はどのように計算されますか？',
  pricingFaqAnswer3:
    'Hashcloudはアップロードしたファイルのみを請求します。閲覧、ダウンロード、変更、または削除には料金はかかりません。例：異なる10つのハッシュタグごとに1 MBのファイルをアップロードした場合、10 MBのストレージ容量を使用しています。',
  contactUsTitle: 'ご質問はありますか？',
  contactUsText: 'お問い合わせはメールでお送りください。できるだけ早く返信いたします。',
  contactUsBtnText: 'お問い合わせ',
  subscribe: '購読する',
  selected: '✅ 選択済み',
  select: '選択',
  per: 'あたり',
  month: '月',
  year: '年',
  currentPlan: '現在のプラン',
  mostPopular: '最も人気',
  subscriptionInactiveMessage:
    'サブスクリプションは無効になりました。利用可能なストレージが1 GBにリセットされました。',
  monthly: '毎月',
  yearly: '毎年',
  standardPlanName: 'スタンダードプラン',
  standardPlanDescription: '10 GBのストレージが含まれています',
  plusPlanName: 'プラスプラン',
  plusPlanDescription: '100 GBのストレージが含まれています',
  ultraPlanName: 'ウルトラプラン',
  ultraPlanDescription: '1000 GBのストレージが含まれています',
  close: '閉じる',
  company: '会社',
  address: '住所',
  contacts: '連絡先',
  representedBy: '代表者',
  registryEntry: '登録エントリ',
  vatId: '付加価値税法 §27a に基づく付加価値税識別番号',
  contentResponsible: 'コンテンツ責任者 (§ 55 Abs. 2 RStV)',
  disputeResolution: '紛争解決',
  hashcloudCompanyName: 'Miosga Software UG (有限責任)',
  hashcloudCompanyAddress: 'Karlsburg 16\n24398 Winnemark\nドイツ',
  hashcloudCompanyContacts: 'Eメール: help@hash.cloud\n電話: +49 (0) 151/22306315',
  hashcloudCompanyRepresentative: 'ティム・ミオスガ',
  hashcloudCompanyRegistryEntry: '商業登記簿に登録 \n商業登記番号: HRB 26713 KI',
  hashcloudCompanyVatId: 'DE297431012',
  hashcloudCompanyContentResponsible: 'ティム・ミオスガ',
  disputeResolutionInfo:
    '欧州委員会はオンライン紛争解決（ODR）のためのプラットフォームを提供しています: <Link>https://ec.europa.eu/consumers/odr</Link><br />消費者仲裁委員会での紛争解決手続きには参加しませんし、参加する義務もありません。',
  imprintPageTitle: 'インプリント（法的通知）',
  termsOfUse: '利用規約',
  privacyPolicy: 'プライバシーポリシー',
  eula: 'EULA（エンドユーザー使用許諾契約）',
  downloadDeutsch: 'ダウンロード（ドイツ語）',
  downloadEnglish: 'ダウンロード（英語）',
  infoPageHeroTitle: '<em>#ハッシュタグ</em>で簡単にファイルを共有。',
  infoPageHeroFootnote:
    'hashcloudを使えば、ファイルを迅速かつ安全に共有できます。仕組みは以下の通りです。',
  infoPageSection1Title: 'ハッシュタグを作成',
  infoPageSection1Content: '家族、友人、同僚に教えるためのハッシュタグを考えましょう。',
  infoPageSection2Title: 'ファイルをアップロード',
  infoPageSection2Content:
    '共有したいファイルをアップロードします。写真、ビデオ、プレゼンテーション、PDF、ZIPファイルなどをアップロードできます。',
  infoPageSection3Title: 'どこからでもアクセス',
  infoPageSection3Content:
    'ハッシュタグを知っている人は誰でも、iOSやWebアプリを通じてアップロードされたファイルにアクセスし、アップロード、削除、編集ができます。',
  infoPageFeature1Title: '迅速なファイル共有',
  infoPageFeature1Content:
    'ファイルを共有したい場合は、ハッシュタグを作成し、ファイルをアップロードし、そのハッシュタグを他の人と共有します。',
  infoPageFeature2Title: '必要なだけの安全性',
  infoPageFeature2Content:
    'ハッシュタグの長さや複雑さを自分で決めて、プライベートまたはパブリックに利用できます。',
  infoPageFeature3Title: 'ドイツでのホスティング',
  infoPageFeature3Content:
    'すべてのファイルはドイツのサーバーに保存され、最も厳しい欧州のデータ保護規制の対象となります。',
  imprint: 'インプリント',
  legalInformation: '法的情報',
  copyright: '著作権 ©',
  hashcloudDirector: 'ディレクター: ティム・ミオスガ',
  hashcloudFooterAddress: 'Karlsburg 16 - 24398 Winnemark',
  hashcloudFooterRegistryNumber: 'HRB 26713 KI',
  hashcloudFooterVatId: '消費税ID: DE297431012',
  eulaShort: 'EULA',
  fileActionReport: '報告する',
  reportHashtagModalTitle: 'このハッシュタグを報告しますか？',
  reportHashtagTooltip: 'ハッシュタグを報告する',
  yes: 'はい',
  no: 'いいえ',
  fileActionsBarDownload: 'ダウンロード',
  fileActionsBarDownloadNumFiles: '{{count}} 個のファイルをダウンロード',
  fileActionsBarDelete: '削除',
  fileActionsBarDeleteNumFiles_one: '{{count}} 個のファイルを削除',
  fileActionsBarDeleteNumFiles_other: '{{count}} 個のファイルを削除',
  fileActionsClearSelection: '選択解除',
  fileActionsBarNumFilesSelected_one: '{{count}} 個のファイルが選択されました',
  fileActionsBarNumFilesSelected_other: '{{count}} 個のファイルが選択されました',
  fileActionsBarNumOwnFilesSelected: '{{count}} 個のファイルがあなたによってアップロードされました',
  filesWereSuccessfullyDeleted: 'ファイルは正常に削除されました',
  fileActionsBarFilesDeleteModalTitle_one: '{{count}} 個のファイルを削除してもよろしいですか？',
  fileActionsBarFilesDeleteModalTitle_other: '{{count}} 個のファイルを削除してもよろしいですか？',
  fileActionDownload: 'ダウンロード',
  fileActionOpen: '開く',
  fileActionsBarOpen: '開く',
};

export default ja;
