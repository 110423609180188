import { FocusEvent, forwardRef, ReactNode, Ref } from 'react';
import cn from 'classnames';

import styles from './Input.module.scss';

import { IInputProps } from 'types/interfaces';
import InputLabel from 'components/InputLabel';

export interface ITextInputProps extends IInputProps<string> {
  type?: 'text' | 'hidden' | 'password';
  className?: string;
  inputClassName?: string;
  placeholder?: string;
  required?: boolean;
  error?: string;
  children?: ReactNode;
  autoFocus?: boolean;
  autoComplete?: string;
  hasError?: boolean;
  disabled?: boolean;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
}

const Input = (props: ITextInputProps, ref: Ref<HTMLInputElement>) => {
  const {
    label,
    type = 'text',
    inputClassName,
    className,
    placeholder,
    error,
    children,
    hasError,
    ...restProps
  } = props;

  return (
    <div
      className={cn(styles.inputContainer, className, {
        [styles.hasError]: Boolean(error || hasError),
      })}
    >
      {label && <InputLabel className={styles.label}>{label}</InputLabel>}

      <div className={cn(styles.input, inputClassName)}>
        <input
          ref={ref}
          type={type}
          className={styles.inputInput}
          placeholder={placeholder}
          {...restProps}
        />
        {children}
      </div>

      {error && <div className={styles.error}>{error}</div>}
    </div>
  );
};

export default forwardRef(Input);
