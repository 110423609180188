import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useLifecycles, usePrevious } from 'react-use';
import { useNavigate, generatePath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import Typewriter from 'typewriter-effect';

import Input from 'components/Input';
import Spinner from 'components/Spinner';

import useFetchFiles from 'hooks/useFetchFiles';

import { SEARCH_RESULTS_PAGE_ROUTE } from 'constants/routes';

import { ReactComponent as IconAppStore } from 'images/icons/AppStore.svg';

import styles from './IndexPage.module.scss';

const IndexPage = () => {
  const [hashtag, setHashtag] = useState('');
  const navigate = useNavigate();

  const { fetchFiles, isLoading, files } = useFetchFiles();
  const prevIsDataLoading = usePrevious(isLoading);

  const [isVisible, setIsVisible] = useState(true);

  const searchButtonRef = useRef<HTMLButtonElement>(null);
  const handleValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    if (value !== '') {
      setIsVisible(false);
    }
    setHashtag(value.replaceAll('#', ''));
  };

  const handleEnterKeyPress = useCallback((event: KeyboardEvent) => {
    if (event.key !== 'Enter') return;

    if (searchButtonRef.current) {
      searchButtonRef.current.click();
    }
  }, []);

  const handleSearchClick = () => {
    if (!hashtag) return;

    fetchFiles({ hashtag });
  };

  // Navigate to the Search page after the files done fetching,
  // and have been saved in the GlobalStateProvider to avoid a second fetching on a Search page
  useEffect(() => {
    const isJustFinishedLoading = prevIsDataLoading && !isLoading;
    if (isJustFinishedLoading) {
      navigate(generatePath(SEARCH_RESULTS_PAGE_ROUTE, { hashtag }));
    }
  }, [prevIsDataLoading, isLoading, hashtag, navigate, files]);

  useLifecycles(
    () => {
      document.addEventListener('keypress', handleEnterKeyPress);
    },
    () => {
      document.removeEventListener('keypress', handleEnterKeyPress);
    }
  );

  const { t } = useTranslation();

  return (
    <>
      <div className={cn(styles.container)}>
        <a
          className={styles.appStoreLink}
          href="https://apps.apple.com/us/app/hashcloud-cloud-storage/id1619279459?itsct=apps_box_link&itscg=30200"
          target="_blank"
          rel="noreferrer"
        >
          <IconAppStore className={styles.appStoreLinkIcon}></IconAppStore>
        </a>
        {/*<div className={styles.copyrightContainer}>
          <div className={styles.copyright}>
            Copyright © 2024<br></br>Miosga Software UG (haftungsbeschränkt)
          </div>
          <div className={styles.dataprivacy}>
            <a href="https://www.apple.com/legal/internet-services/itunes/dev/stdeula/">
              Nutzungsbedingungen
            </a>
            <br />
            <a href="https://miosga-software.com/privacypolicy.html">Datenschutzerklärung</a>
          </div>
        </div>*/}
        {/*<div className={styles.child}></div>*/}
        <div className={styles.containerInner}>
          {/* <div className={styles.infoGroup}>
            <Typewriter
              onInit={typewriter => {
                typewriter
                  .typeString('#hashtag')
                  .pauseFor(1000)
                  .deleteAll()
              }}
              options={{
                loop: true,
                wrapperClassName: styles.typeWriterText,
                cursorClassName: styles.cursor,
              }}
            />
          </div>*/}
          <div className={styles.centerContainer}>
            <div className={styles.searchInputGroup}>
              {isLoading ? (
                <div className={styles.spinnerContainer}>
                  <Spinner className={styles.spinner} />
                </div>
              ) : (
                <button
                  ref={searchButtonRef}
                  className={styles.searchButton}
                  onClick={handleSearchClick}
                ></button>
              )}

              <Input
                autoFocus={true}
                onChange={handleValueChange}
                value={hashtag ? '#' + hashtag : ''}
                className={styles.inputContainer}
                inputClassName={styles.searchInput}
                name="hashtag"
                type="text"
                placeholder="#hashtag"
                disabled={isLoading}
              />

              {!isLoading && isVisible && (
                <div className={styles.infoGroup}>
                  <Typewriter
                    onInit={typewriter => {
                      typewriter.pauseFor(1000).deleteAll().start();
                    }}
                    options={{
                      loop: true,
                      strings: [
                        '#MatheUebung1_TU',
                        '#FamMuellerItal24',
                        '#UniDueMakroWS23',
                        '#SteuerMueller23',
                      ],
                      autoStart: true,
                      wrapperClassName: styles.typeWriterText,
                      cursorClassName: styles.cursor,
                    }}
                  />
                </div>
              )}
            </div>

            <div className={styles.fileLabel}>
              <u>{t('instructions')}</u>
              <br></br>
              <br></br>
              {t('downloadApp')}
              <br></br>
              {t('createAndUpload')}
              <br></br>
              {t('typeIn')}
              <br></br>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IndexPage;
