import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import en from 'translations/en';
import de from 'translations/de';
import fr from 'translations/fr';
import es from 'translations/es';
import ja from 'translations/ja';
import zh from 'translations/zh';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    resources: {
      en: {
        translation: en,
      },
      de: {
        translation: de,
      },
      fr: {
        translation: fr,
      },
      es: {
        translation: es,
      },
      ja: {
        translation: ja,
      },
      zh: {
        translation: zh,
      },
    },
    interpolation: {
      escapeValue: false,
    },
    react: {
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'em'],
    },
  });

export default i18n;
